<template>
  <div class="header">
    <a href="#/">
      <img class="logo" src="@/assets/img/logo.png">
    </a>
    <div class="right">
      <div v-if="showWallet && address" class="wallet">{{ getEllipsis(address, '...', 4, 4) }}</div>
      <div v-if="showWallet && !address" class="wallet" @click="init">Connect Wallet</div>
      <img class="menu" src="@/assets/img/icon_menu.svg" @click="router.push('/setting')">
    </div>
  </div>
  <div class="h48"></div>

  <!--风险免责声明弹框-->
  <van-popup
      v-model:show="showState"
      round
      :close-on-click-overlay="false"
      :overlay-style="{ background: 'rgba(255, 255, 255, 0.8)' }"
  >
    <div class="statement">
      <div class="title">Risk Disclaimer</div>
      <div class="content">
        <p>Before you proceed with investing in Non-Fungible Tokens (NFTs) that represent real-world assets on
          My World, it's important to understand and consider the inherent risks. By continuing, you acknowledge and agree to the following:</p>
        <p><span>Market Volatility:</span> The value of NFTs can be highly volatile. Prices can fluctuate widely in a short period, potentially leading to significant losses.</p>
        <p><span>Liquidity Risk:</span> NFTs, especially those tied to real-world assets, may face liquidity issues. It might be difficult to sell or trade your NFTs quickly or without financial loss.</p>
        <p><span>Regulatory Uncertainty:</span> The legal framework around NFTs and tokenized assets is evolving. Future regulatory changes could impact the validity, ownership, and transferability of NFTs.</p>
        <p><span>Asset Valuation:</span> Valuations of real-world assets represented by NFTs are subject to change. These changes can affect the underlying value of your NFT investment.</p>
        <p><span>Technological Risks:</span> The blockchain technology underlying NFTs is subject to risks, including but not limited to, hacking, data loss, and operational failures that could lead to the loss of your investment.</p>
        <p><span>Legal and Compliance Risks:</span> Owning NFTs tied to real-world assets may involve complex legal and compliance obligations. It's essential to understand these before investing.</p>
        <p><span>Risk of Loss:</span> Investing in NFTs should be done with caution. There is a risk of losing your entire investment.</p>
        <p><span>Independent Advice:</span> We strongly recommend that you conduct your own due diligence and consider seeking advice from independent legal, financial, and tax advisors before making any investment.</p>
        <div class="bottom">By accessing and using this information, you acknowledge and agree to the terms of this disclaimer.</div>
      </div>
      <div v-if="!(second < 1)" class="btn read">Agree ({{ second }}s)</div>
      <div v-else class="btn" @click="handleRead">Agree</div>
    </div>
  </van-popup>

  <!--绑定钱包地址弹框-->
  <van-popup
      v-model:show="show"
      round
      closeable
      :close-on-click-overlay="false"
      :overlay-style="{ background: 'rgba(255, 255, 255, 0.8)' }"
  >
    <div class="bind">
      <div class="title">Connect Wallet</div>
      <div class="form">
        <div class="item">
          <div class="label">BSC Wallet Address</div>
          <input v-model="form.coin_address" readonly placeholder="Please insert address">
        </div>
        <div class="item">
          <div class="label">Safety PIN</div>
          <van-password-input
              :value="form.pay_code"
              :gutter="10"
              :focused="showKeyboard"
              @focus="showKeyboard = true"
          />
        </div>
        <div class="item">
          <div class="label">Email Code</div>
          <div class="flex">
            <input v-model="form.verification_code" placeholder="Email Code">
            <van-button v-show="!isSend" :loading="loading" type="primary" class="send" @click="handleSend">Send Code</van-button>
            <div v-show="isSend" class="btn">{{ second1 }}s</div>
          </div>
        </div>
      </div>
      <van-button :loading="loading1" type="primary" @click="handleSubmit">Confirm</van-button>
    </div>
  </van-popup>

  <!-- 数字键盘 -->
  <van-number-keyboard
      v-model="form.pay_code"
      :show="showKeyboard"
      z-index="3000"
      @blur="showKeyboard = false"
  />

  <!--链接已绑定的钱包地址-->
  <van-popup
      v-model:show="showConnect"
      round
      :close-on-click-overlay="false"
      :overlay-style="{ background: 'rgba(255, 255, 255, 0.8)' }"
  >
    <div class="connect">
      <div class="title">Connect Wallet</div>
      <div class="text">Connect your linked wallet address to the Binance Smart Chain network.</div>
      <div class="label">Linked Wallet Address:</div>
      <div class="address">{{ bscAddr }}</div>
      <div class="btn" @click="showConnect = false">Return Back</div>
    </div>
  </van-popup>

<!--  <div class="service" @click="openService"></div>-->
</template>

<script setup>
  import { ref, onMounted, reactive } from 'vue'
  import { useRouter } from "vue-router";
  import { showSuccessToast, showToast } from "vant";
  import { getEllipsis } from "@/utils";
  import { addUserCoin, getUserCoin } from '@/api/wallet'
  import { getVerCode } from "@/api/user";

  const Web3Modal = window.Web3Modal.default;
  const WalletConnectProvider = window.WalletConnectProvider.default;

  // Web3modal instance
  let web3Modal, network, provider, selectedAccount;
  let injectedWeb3 = null;
  const infura_key = 'c3e254116c559ae5e58746e69fb38787';
  let provider1,signer,gasPrice,usdtContract,conContract;

  const props = defineProps({
    showWallet: {
      type: Boolean,
      default: false
    }
  })

  const router = useRouter()
  const address = ref('')

  const showState = ref(localStorage.getItem('read') !== '1' && props.showWallet)
  const second = ref(5)
  let timer = setInterval(() => {
    second.value--
    if(second.value < 1) {
      clearInterval(timer)
    }
  }, 1000)
  function handleRead() {
    init()
    showState.value = false
    localStorage.setItem('read', '1')
  }

  const show = ref(false)

  const form = reactive({
    coin_address: '',
    pay_code: '',
    verification_code: ''
  })
  const showKeyboard = ref(false)
  const loading = ref(false)
  const loading1 = ref(false)

  function handleSubmit() {
    if(form.coin_address === '') {
      showToast('Please Enter the Wallet Address')
      return
    }
    if(form.pay_code === '') {
      showToast('Please Enter the Safety PIN')
      return
    }
    if(form.pay_code.length < 6) {
      showToast('Please Enter 6 Digits')
      return
    }
    if(form.verification_code === '') {
      showToast('Please Enter the Email Code')
      return
    }
    loading1.value = true
    form.type = 1
    addUserCoin(form).then(res => {
      if(res.success) {
        showSuccessToast('Successful operation')
        show.value = false
        clearInterval(timer1)
        isSend.value = false
        second1.value = 59
      } else {
        showToast(res.msg)
      }
      loading1.value = false
    })
  }

  const isSend = ref(false)
  const second1 = ref(59)
  let timer1 = null
  function handleSend() {
    loading.value = true
    getVerCode().then(res => {
      if(res.success) {
        showSuccessToast('Sent successfully')
        isSend.value = true
        timer1 = setInterval(() => {
          if(second1.value <= 1) {
            clearInterval(timer1)
            isSend.value = false
            second1.value = 59
            return
          }
          second1.value--
        }, 1000)
      } else {
        showToast(res.msg)
      }
      loading.value = false
    })
  }

  const showConnect = ref(false)
  const bscAddr = ref('')

  onMounted(() => {
    if(props.showWallet && !showState.value) {
      init()
    }
  })

  const emits = defineEmits(['getContract'])

  async function init() {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: "https://uniswap.bridge.walletconnect.org",
          rpc: {
            56: 'https://bsc-dataseed.binance.org/',
            66: 'https://exchainrpc.okex.org',
            97: 'https://data-seed-prebsc-1-s3.binance.org:8545/',
            137: 'https://polygon-rpc.com/',
          },
          // Mikko's test key - don't copy as your mileage may vary
          infuraId: infura_key,
        }
      },
    };
    web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions, // required
      disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
    });
    try {
      provider = await web3Modal.connect();
      provider.enable()
    } catch (e) {
      console.log("Could not get a wallet connection", e);
      alert("cannot connect wallet");
      return;
    }
    // Subscribe to accounts change
    provider.on("accountsChanged", async (accounts) => {
      await fetchAccountData();
    });
    // Subscribe to chainId change
    provider.on("chainChanged", async (chainId) => {
      await fetchAccountData();
    });
    // Subscribe to networkId change
    provider.on("networkChanged", async (networkId) => {
      await fetchAccountData();
    });
    provider.on("disconnect", async (code, reason) => {
      await onDisconnect();
    });
    await fetchAccountData(provider);
  }

  async function fetchAccountData() {
    const web3 = new Web3(provider);
    injectedWeb3 = web3;
    const accounts = await web3.eth.getAccounts();
    selectedAccount = accounts[0];
    network = await web3.eth.net.getId();
    if (network != 56) {
      showToast('Please use Binance Smart Chain to connect')
      await onDisconnect();
    } else {
      const res = await getUserCoin()
      if(!res.data.length) {
        show.value = true
        form.coin_address = selectedAccount
      } else {
        const result = res.data.findIndex(item => {
          return item.type === 1
        })
        if(result < 0) {
          show.value = true
          form.coin_address = selectedAccount
        } else {
          bscAddr.value = res.data[result].addr
          if(bscAddr.value !== selectedAccount) {
            showConnect.value = true
            return
          }
        }
      }
      address.value = selectedAccount;
      gasPrice = await web3.eth.getGasPrice();
      provider1 = new ethers.providers.Web3Provider(web3.currentProvider);
      signer = provider1.getSigner();
      usdtContract = new ethers.Contract(contractConfig.usdtAddress, usdtAbi, signer);
      conContract = new ethers.Contract(contractConfig.conAddress, conAbi, signer);
      emits('getContract', {
        address: selectedAccount,
        usdtContract,
        conContract,
        gasPrice
      })
    }
  }

  async function onDisconnect() {
    console.log("Killing the wallet connection", provider);
    // TODO: Which providers have close method?
    if (address.value) {
      await web3Modal.clearCachedProvider();
      await provider.disconnect()
      provider = null;
      selectedAccount = null;
      address.value = '';
    }
  }

  function openService() {
    Intercom('show')
  }
</script>

<style scoped lang="less">
.header{
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background: #fff;
  box-shadow: 0 3px 5px 0 rgba(0, 194, 152, 0.25);
  z-index: 9;

  .logo{
    width: 32px;
  }
  .right{
    display: flex;
    align-items: center;

    .menu{
      width: 20px;
    }
    .wallet{
      height: 24px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      border-radius: 31px;
      background: #00C298;
      box-shadow: 0 1px 5px 0 rgba(0, 194, 152, 0.25);
      color: #fff;
      margin-right: 16px;
      font-family: 'Poppins-Medium';
    }
  }
}
.h48{
  height: 48px;
}
.statement{
  width: 328px;
  padding: 24px 16px;

  .title{
    font-size: 21px;
    text-align: center;
    font-family: 'Poppins-Medium';
    margin-bottom: 16px;
  }
  .content{
    line-height: 16px;
    font-family: 'Poppins-Light';
    height: 400px;
    overflow-y: auto;
    text-align: justify;

    p{
      font-family: 'Poppins-Light';
      margin-top: 8px;

      span{
        font-family: 'Poppins-Medium';
      }
    }
    p:first-child{
      margin-top: 0;
    }
    .bottom{
      font-family: 'Poppins-Medium';
      margin-top: 16px;
    }
  }
  .content::-webkit-scrollbar{
    display: none;
  }
  .btn{
    height: 40px;
    margin-top: 16px;
    box-shadow: 0 1px 5px 0 rgba(0, 194, 152, 0.25);
  }
  .read{
    background: #CDCDCD;
  }
}
.bind{
  width: 328px;
  padding: 24px;

  .title{
    font-size: 21px;
    font-family: 'Poppins-Medium';
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #CDCDCD;
  }
  .form{
    margin-bottom: 24px;

    .item:first-child{
      margin-top: 0;
    }
    .item{
      margin-top: 16px;

      .label{
        font-size: 16px;
        margin-bottom: 8px;
      }
      input{
        height: 36px;
        border-radius: 10px;
        border: 1px solid #F0F0F0;
        box-shadow: 0 1px 5px 0 rgba(0, 194, 152, 0.25);
        padding-left: 16px;
        font-size: 14px;
      }
      .van-password-input{
        margin: 0;
      }
      :deep(.van-password-input__security){
        height: auto;
      }
      :deep(.van-password-input__security li){
        border-radius: 10px;
        height: 38px;
        border: 1px solid #F0F0F0;
        box-shadow: 0 1px 5px 0 rgba(0, 194, 152, 0.25);
      }
      .flex{
        display: flex;
        justify-content: space-between;

        input{
          width: 150px;
        }
        .send{
          width: 120px;
          height: 36px;
          margin-top: 0;
        }
        .btn{
          width: 124px;
          height: 36px;
          background: rgba(0,194,152,0.5);
        }
      }
    }
  }
  .van-button--primary{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    background: #00C298;
    border: none;

    :deep(.van-button__text){
      font-size: 16px;
    }
  }
}
.connect{
  width: 328px;
  padding: 24px;

  .title{
    font-size: 21px;
    font-family: 'Poppins-Medium';
    padding-bottom: 16px;
  }
  .text{
    font-size: 16px;
    line-height: 20px;
  }
  .label{
    font-family: 'Poppins-Medium';
    font-size: 16px;
    margin: 20px 0 12px 0;
  }
  .address{
    border-radius: 10px;
    border: 1px solid #F0F0F0;
    font-size: 16px;
    word-break: break-all;
    line-height: 20px;
    box-shadow: 0 1px 5px 0 rgba(0, 194, 152, 0.25);
    padding: 4px 8px;
    margin-bottom: 16px;
  }
  .btn{
    height: 40px;
  }
}
.service{
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: fixed;
  right: 16px;
  bottom: 130px;
  background: url("@/assets/img/icon_service.png") no-repeat #000000 center center;
  background-size: 36px 36px;
  z-index: 999;
}
</style>
