import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import 'vant/lib/index.css';
import 'amfe-flexible'
import Header from './components/Header.vue'
import TabBar from './components/TabBar.vue'
import { Button, Swipe, SwipeItem, Uploader, Popup, Tab, Tabs, PasswordInput, NumberKeyboard, CheckboxGroup, Checkbox,
  Divider, Stepper, RadioGroup, Radio, Progress, CountDown, Search, Popover, Sticky } from "vant";

const app = createApp(App)
app.use(router)
app.component('Header', Header)
app.component('TabBar', TabBar)
app.use(Button).use(Swipe).use(SwipeItem).use(Uploader).use(Popup).use(Tab).use(Tabs).use(PasswordInput)
  .use(NumberKeyboard).use(CheckboxGroup).use(Checkbox).use(Divider).use(Stepper).use(RadioGroup).use(Radio).use(Progress)
  .use(CountDown).use(Search).use(Popover).use(Sticky)
app.mount('#app')
