import { get, post } from './http'

// 会员资产
export const getWalletInfo = p => get('/index/User/getWalletInfo', p);

// 资金记录
export const transferLog = p => get('/index/User/transferLog', p);

// 钱包地址列表
export const getUserCoin = p => get('/index/User/getUserCoin', p);

// 添加编辑钱包
export const addUserCoin = p => post('/index/User/addUserCoin', p);

// 提现配置
export const getConfig = p => get('/index/index/getConfig', p);

// 申请提现
export const withdrawApply = p => post('/index/My/withdrawApply', p);
