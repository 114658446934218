// 保留小数位
export const getFloat = (val, num = 2) => {
  if (!Number(num)) {
    num = 2
  }
  switch(num){
    case 4:
      val = Math.floor(parseFloat(val)* 10000)/10000;
      break;
    case 10:
      val = Math.floor(parseFloat(val)* 10000000000)/10000000000;
      break;
    default:
      val = Math.floor(parseFloat(val)* 100)/100;
      break;
  }
  if (!val) {
    val = 0
    val = parseFloat(val).toFixed(num);
  }else{
    val = parseFloat(val).toFixed(num);
  }
  return val;
}

// 隐藏信息
export const getEllipsis = (val, symbol, m, n) => {
  if(m === 'front') {
    const end = val.substr(-n)
    return symbol + end
  } else {
    const start = val.substr(0, m)
    const end = n ? val.substr(-n) : ''
    return start + symbol + end
  }
}
