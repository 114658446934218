<template>
  <div class="tab">
    <div class="main">
      <div :class="{active: activeIndex === 0}" class="item" @click="router.push('/rwa')">
        <img class="icon" src="@/assets/img/icon_rwa.svg">
        <div class="wrap">
          <img class="icon" src="@/assets/img/icon_rwa_active.svg">
        </div>
      </div>
      <div :class="{active: activeIndex === 1}" class="item" @click="router.push('/mall')">
        <img class="icon" src="@/assets/img/icon_shop.svg">
        <div class="wrap">
          <img class="icon" src="@/assets/img/icon_shop_active.svg">
        </div>
      </div>
      <div :class="{active: activeIndex === 2}" class="item" @click="router.push('/')">
        <img class="icon" src="@/assets/img/icon_home.svg">
        <div class="wrap">
          <img class="icon" src="@/assets/img/icon_home_active.svg">
        </div>
      </div>
      <div :class="{active: activeIndex === 3}" class="item" @click="router.push('/game')">
        <img class="icon" src="@/assets/img/icon_sandbox.svg">
        <div class="wrap">
          <img class="icon" src="@/assets/img/icon_sandbox_active.svg">
        </div>
      </div>
      <div :class="{active: activeIndex === 4}" class="item" @click="router.push('/mine')">
        <img class="icon" src="@/assets/img/icon_mine.svg">
        <div class="wrap">
          <img class="icon" src="@/assets/img/icon_mine_active.svg">
        </div>
      </div>
    </div>
  </div>
  <div class="h86"></div>
</template>

<script setup>
  import { useRouter } from 'vue-router'
  import { showToast } from "vant";

  const router = useRouter()
  defineProps({
    activeIndex: {
      type: Number,
      default: 2
    }
  })

  function noOpen() {
    showToast('Not Open Yet')
  }
</script>

<style scoped lang="less">
.tab{
  width: 100%;
  position: fixed;
  height: 86px;
  left: 0;
  bottom: 0;
  background: #ffffff;
  padding: 8px 16px 18px 16px;

  .main{
    height: 60px;
    border-radius: 56px;
    box-shadow: 0 1px 5px 0 rgba(0, 194, 152, 0.25);
    display: flex;
    align-items: center;
    padding: 0 12px;

    .item{
      width: 20%;
      height: 24px;
      position: relative;

      .icon{
        width: 24px;
        margin: 0 auto;
      }
      .wrap{
        position: absolute;
        width: 48px;
        height: 48px;
        border-radius: 15px;
        background: #00C298;
        left: 50%;
        bottom: 2px;
        transform: translateX(-50%);
        display: none;
        align-items: center;
        justify-content: center;
      }
    }
    .active{
      .wrap{
        display: flex;
      }
      >.icon{
        display: none;
      }
    }
  }
}
.h86{
  height: 86px;
}
</style>
