import { get, post, postForm } from './http'

// 注册
export const register = p => post('/index/Register/register', p);

// 发送验证码
export const getCode = p => post('/index/Register/getCode', p);

// 登录
export const login = p => post('/index/Register/login', p);

// 找回密码
export const passwordReset = p => post('/index/Register/passwordReset', p);

// 用户信息
export const userInfo = p => get('/index/User/userInfo', p);

// 退出登录
export const logout = p => post('/index/register/logout', p);

// 设置/编辑安全密码
export const addPwd = p => post('/index/User/addpwd', p);

// 登录状态发送短信验证码
export const getVerCode = p => post('/index/User/getCode', p);

// 绑定上级
export const bindParent = p => post('/index/User/bindParent', p);

// 团队信息
export const teamInfo = p => get('/index/User/teamInfo', p);

// 上传图片
export const uploadImg = p => postForm('/index/index/uploadImg', p);

// 完善资料
export const addComplete = p => post('/index/User/addComplete', p);

// 修改登录密码
export const upLoginPwd = p => post('/index/User/upLoginpwd', p);

// 会员级别距离下一级
export const levelInfo = p => get('/index/User/levelInfo', p);

// 用户收货地址列表
export const getUserAddress = p => get('/index/User/getUserAddress', p);

// 添加编辑收货地址
export const addUserAddress = p => post('/index/User/addUserAddress', p);

// 国家列表
export const countList = p => get('/index/index/countList', p);

// 删除收货地址
export const delUserAddress = p => post('/index/User/delUserAddress', p);

// 创建clientSecret
export const createCheckoutSession = p => post('/index/My/createCheckoutSession',p);

// 新实名获取accessTokens
export const createToken = p => post('/index/User/createToken',p);
